var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    {
      staticClass: "iq-time-picker",
      attrs: {
        "label-width": _vm.labelWidth,
        label: _vm.label,
        prop: _vm.prop,
      },
    },
    [
      _c("el-time-select", {
        ref: "picker",
        staticClass: "iq-time-picker__input",
        class: { fullWidth: _vm.size === "fullWidth" },
        style: { width: _vm.width },
        attrs: {
          "prefix-icon": _vm.prefix,
          disabled: _vm.disabled,
          size: _vm.size,
          type: _vm.type,
          placeholder: _vm.placeholder,
          format: _vm.format,
          "picker-options": _vm.pickerOptions,
        },
        on: { change: _vm.onChange, blur: _vm.onBlur, focus: _vm.onFocus },
        model: {
          value: _vm.inputVal,
          callback: function ($$v) {
            _vm.inputVal = $$v
          },
          expression: "inputVal",
        },
      }),
      _vm.icon && !_vm.disabled
        ? _c("div", {
            class: ["iq-time-picker__icon", _vm.iconClass],
            on: { click: _vm.iconClick },
          })
        : _vm._e(),
      _vm.isShowDisabledMessage
        ? _c("span", { staticClass: "info-message" }, [
            _vm._v(" " + _vm._s(_vm.disabledMessage) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }