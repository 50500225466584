<template>
  <el-form-item
    class="iq-time-picker"
    :label-width="labelWidth"
    :label="label"
    :prop="prop"
  >
    <el-time-select
      ref="picker"
      v-model="inputVal"
      :prefix-icon="prefix"
      :class="{ fullWidth: size === 'fullWidth' }"
      :disabled="disabled"
      class="iq-time-picker__input"
      :size="size"
      :type="type"
      :placeholder="placeholder"
      :format="format"
      :picker-options="pickerOptions"
      :style="{ width }"
      @change="onChange"
      @blur="onBlur"
      @focus="onFocus"
    />
    <div
      v-if="icon && !disabled"
      :class="['iq-time-picker__icon', iconClass]"
      @click="iconClick"
    />
    <span v-if="isShowDisabledMessage" class="info-message">
      {{ disabledMessage }}
    </span>
  </el-form-item>
</template>

<script>
export default {
  name: 'IqSelectTimeForm',
  props: {
    icon: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: 'el-icon-date',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pickerOptions: {
      require: true,
    },
    label: {
      type: String,
      default: '',
    },
    labelWidth: {
      type: String,
      default: 'auto',
    },
    format: {
      type: String,
    },
    prop: {
      type: String,
    },
    value: {
      required: true,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
    },
    size: {
      type: String,
      default: 'small',
    },
    valueFormat: {
      type: String,
      default: 'timestamp',
    },
    placeholderBottom: {
      type: String,
      default: '',
    },
    disabledMessage: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '100%',
    },
  },
  data() {
    return {
      iconClass: 'empty',
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    isShowPlaceholderBottom() {
      return this.placeholderBottom !== ''
    },
    isShowDisabledMessage() {
      return this.disabled && this.disabledMessage !== ''
    },
  },
  mounted() {
    document
      .querySelectorAll('.el-date-editor input')
      .forEach(e => (e.readOnly = true))
  },
  methods: {
    onChange(event) {
      this.$emit('onChange', event)
    },
    iconClick() {
      if (this.iconClass === 'filled') {
        this.inputVal = ''
        this.iconClass = 'empty'
      } else if (this.iconClass === 'empty') {
        this.iconClass = 'open'
        this.$refs.picker.focus()
      }
    },
    onBlur() {
      this.blurWhenFilled = Boolean(this.inputVal)
      this.iconClass = this.value ? 'filled' : 'empty'
    },
    onFocus() {
      this.iconClass = 'open'
    },
  },
}
</script>

<style lang="sass">
$color-blue-primary: #4CA7D8

.iq-time-picker
  position: relative
  .el-input--suffix .el-input__inner
    padding-right: 20px
  &__icon
    position: absolute
    top: 0
    right: 12px
    width: 40px
    height: 40px
    background-position: center
    background-repeat: no-repeat
    transition: transform 0.3s ease
    cursor: pointer
    &.empty
      background-image: url('~@/assets/img/angle-down-icon.svg')
    &.open
      transform: rotate(180deg)
      background-image: url('~@/assets/img/angle-down-icon.svg')
    &.filled
      background-image: url('~@/assets/img/close-icon.svg')
  .info-message
    font-size: 11px
    color: $btn-orange!important
    position: absolute
    top: 100%
    left: 0
    line-height: 1.7
  .el-form-item__label
    font-family: Roboto, sans-serif
    font-style: normal
    font-weight: normal !important
    font-size: 14px !important
    line-height: 22px
    color: #606266
  .el-form-item__label:before
    content: '' !important
  .el-select-dropdown
    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.1))
    border: none
    box-shadow: none
  .el-select-dropdown__item.selected
    font-family: Roboto, sans-serif
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 22px
    display: flex
    align-items: center
    font-feature-settings: 'tnum' on, 'lnum' on
    color: $color-blue-primary
  .el-form-item
    margin-bottom: 12px!important

    .el-form-item__label
      margin-bottom: 5px !important

    .el-icon-arrow-up:before
      content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.75 22.625L12.5 16.375L6.25 22.625' stroke='%23C0C4CC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important

@media (max-width: 450px)
  .el-picker-panel
    .el-scrollbar
      .time-select-item.selected:not(.disabled)
        position: relative
        &::before
          content: url('~@/assets/img/check-icon.svg')
          position: absolute
          top: 50%
          transform: translateY(-50%)
          right: 20px
          line-height: 14px
  .el-dialog__body
    .el-form-item__content
      .el-date-editor .el-icon-circle-close
        display: none
</style>
